<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">เพิ่มร้าน</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
          <!--begin::Form-->
          <Form
            @submit="onSubmitStore"
            :validation-schema="store"
            id="kt_account_profile_details_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <BaseInput
                label="ชื่อร้าน"
                required="required"
                name="name"
                type="text"
              />

              <BaseImage
                label="รูปภาพ"
                required="required"
                name="image_store"
                :aspectRatio="4 / 3"
                :height="1200"
                :width="1200"
              />

               <BaseInput
                  required="required"
                  label="รายละเอียดร้าน"
                  as="textarea"
                  name="detail"
                />


              <BaseInput
                label="บ้านเลขที่"
                required="required"
                name="house_no"
                type="text"
              />

              <BaseInput label="หมู่ที่" name="village_no" type="text" />

              <BaseInput label="ซอย" name="lane" type="text" />

              <BaseInput label="ถนน" name="road" type="text" />

              <BaseSelect
                required="required"
                label="อำเภอ"
                name="sub_district"
                type="text"
                :data="uniqueAmphoe"
                v-model="data_c.amphoe"
                l="amphoe"
                k="amphoe"
                d="amphoe"
              />

              <BaseSelect
                required="required"
                label="ตำบล"
                name="district"
                type="text"
                :data="uniqueDistrict"
                v-model="data_c.district"
                @change="setZipcode(data_c.district)"
                l="district"
                k="district"
                d="district"
              />

              <BaseInput
                label="เบอร์โทรศัพท์"
                required="required"
                name="tel"
                type="text"
              />

              <BaseInput label="เว็บไซต์" name="website" type="text" />

              <BaseInput label="Line" name="line" type="text" />

              <BaseInput label="Facebook" name="facebook" type="text" />
            </div>
            <!--end::Card body-->

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                type="submit"
                id="kt_account_profile_details_submit"
                ref="submitButton"
                class="btn btn-primary"
              >
                <span class="indicator-label"> ยืนยัน </span>
                <span class="indicator-progress">
                  กรุณารอสักครู่ ...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
            </div>
            <!--end::Actions-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/modals/BaseImage.vue";
import BaseInput from "@/components/modals/BaseInput.vue";
import BaseSelect from "@/components/modals/BaseSelect.vue";
import json from "@/core/services/DataStore.json";
import { defineComponent, onMounted, ref, computed, reactive } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useStores from "@/core/services/api/travel/market_sun/store";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
export default defineComponent({
  name: "market_sun-store-create",
  components: {
    Form,
    BaseInput,
    BaseSelect,
    BaseImage,
  },
  setup() {
    const { stoerStore } = useStores();
    const { Sconfirm, SToast, SconfirmNc } = useSweetalert();
    const submitButton = ref(null);
    const imageLogo = ref("");
    const items = ref(json);
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const data_c = reactive({
      province: "แม่ฮ่องสอน",
      amphoe: "",
      district: "",
      zipcode: "",
    });

   
    const store = Yup.object().shape({
      name: Yup.string().required("กรุณากรอก ชื่อร้าน").label("ชื่อร้าน"),
      image_store: Yup.string().required("กรุณาเลือก รูปภาพ").label("รูปภาพ"),
      detail: Yup.string().required("กรุณากรอก รายละเอียดร้าน"),
      house_no: Yup.string()
        .required("กรุณากรอก บ้านเลขที่")
        .label("บ้านเลขที่"),
      sub_district: Yup.string().required("กรุณาเลือก อำเภอ").label("อำเภอ"),
      district: Yup.string().required("กรุณาเลือก ตำบล").label("ตำบล"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
    });

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter((item) => item.province === data_c.province);
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter((item) => item.amphoe === data_c.amphoe);
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    const onSubmitStore = (values) => {
      values.postal_code = data_c.zipcode;
      Sconfirm("ยืนยัน เพิ่มร้าน", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
              submitButton.value.setAttribute("disabled", "disabled");
            }

            await stoerStore(values)
              .then(() => {
                SToast("success", "เพิ่ม ร้าน สำเร็จ");
              })
              .catch(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");

                SconfirmNc(
                  "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                  "error",
                  "ลองอีกครั้ง !"
                );
              });
          }
        }
      );
    };

    function setZipcode(val) {
      let result = val;

      let data = items.value.filter(
        (item) => item.district === result && item.amphoe === data_c.amphoe
      );
      data_c.zipcode = data[0].zipcode;
    }
    function satImageLogo(image) {
      imageLogo.value = image;
    }

    function deleteImage() {
      imageLogo.value = "";
    }

    onMounted(() => {
      checkPage("market_sun-store-create");
      setCurrentPageBreadcrumbs("เพิ่มร้าน", [
        "ส่งเสริมการท่องเที่ยว",
        "ตลาดนัดวันอาทิตย์",
        "ร้าน",
      ]);
    });

    return {
      store,
      imageLogo,
      submitButton,
      onSubmitStore,
      satImageLogo,
      deleteImage,
      data_c,
      uniqueAmphoe,
      uniqueDistrict,
      setZipcode,
    };
  },
});
</script>

