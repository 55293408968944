import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useStores() {

  const router = useRouter();

  const getStores = async (page) => {
    return await ApiService.query("market_sun_stores",page);
  };

  const getStore = async (id) => {
    return await  ApiService.get("market_sun_stores/" + id);
  };

  const destroyStore = async (id) => {
    await ApiService.delete("market_sun_stores/" + id);
  };

  const stoerStore = async (data) => {
    await ApiService.post("market_sun_stores", data);
    await router.push({ name: "apps.travels.market_sun.store.index" });
  };

  const updateStore = async (data) => {
    await ApiService.put("market_sun_stores/" + data.id, data);
    await router.push({
      name: "apps.travels.market_sun.store.edit",
      params: { id: data.id },
    });
  };

  

  return {
    getStores,
    getStore,
    destroyStore,
    stoerStore,
    updateStore,
  };
}
